import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface TypeRoutine {
  id: string
  nome: string
  tipoRotina: number | string
  condominioId: string
  observacao: string
  tipoItemChecklistRotinaIds?: string[]
  rotinaId?: string
  obraId?: string
  tipoItemChecklistRotina?: [
    {
      id?: string
      nome?: string
      itemsChecklistRotina?: [
        {
          nome?: string
        }
      ]
    }
  ]
  // localId: string
  // subLocalId: string
  // subSubLocalId: string
  // sistemaId: string
  // itemManutencaoId: string
  // subItemManutencaoId: string
  // descricao: string
  // uploadsIds?: string[]
  // urgente?: boolean
  // localDescricao?: string
}
export interface FilterTypeRoutine {
  nome?: string
  tipoRotina?: string | number
}
export interface EditTypeRoutine {
  id: string
  nome: string
  rotinaId?: string
  condominiumId?: string
  observacao?: string
  tipoRotina?: number | string
  tipoItemChecklistRotinaIds?: string[]
  tipoItemChecklistRotina?: [
    {
      id?: string
      nome?: string
      itemsChecklistRotina?: [
        {
          nome?: string
        }
      ]
    }
  ]
}
export interface DeleteItemTypeRoutine {
  id: string
  tipoItemChecklistRotina?: [
    {
      id: string
      nome: string
      itemsChecklistRotina?: [
        {
          nome: string
        }
      ]
    }
  ]
}
/**
 * listagem rotinas por obra
 */
export interface ListRoutineByIdInWorkOptions {
  id: string
  nome: string
  tipoRotina: number | string
  observacao: string
  condominioId: string
  tipoItemChecklistRotina?: [
    {
      id?: string
      nome?: string
      itemsChecklistRotina?: [
        {
          nome?: string
          status?: string | number
        }
      ]
    }
  ]
}
export interface DeleteRoutineCondominiumOptions {
  id: string
  rotinaId: string
  condominioId: string
}
export interface ListRoutineByIdInWork {
  id: string
  obraId: string
  rotinaId: string
}
export interface DeleteRoutineCondominiumWork {
  id: string
  condominiumId: string
  rotinaId: string
}

export type FetchTypeRoutineOptions = PaginatedEndpointOptions<TypeRoutine>

export type FetchTypeRoutineResponse = PaginatedEndpointResponse<TypeRoutine>

/**
 * Carrega lista de atividades e rotinas.
 */
export async function fetchTypesRoutine(options?: FetchTypeRoutineOptions) {
  try {
    console.log(options)
    const { data } = await api.get<FetchTypeRoutineResponse>(
      'Rotina/BuscaRotina',
      {
        params: options
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega um tipo de item de rotinas pelo id.
 */
export async function fetchTypeRoutine(id: string) {
  try {
    const { data } = await api.get<TypeRoutine>(`Rotina/BuscaRotinaId/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de Rotinas de uma Obra por Id em uma Obra
 */
export async function fetchListRoutineByIdInWork(
  options: ListRoutineByIdInWorkOptions
) {
  try {
    const { data } = await api.get<ListRoutineByIdInWorkOptions>(
      `/Rotina/BuscaRotinaPorIdEmObra`,
      { params: options }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
export type SaveTypeRoutinePayload = SaveResourcePayload<TypeRoutine>
export type EditTypeRoutinePayload = SaveResourcePayload<EditTypeRoutine>

/**
 * Carrega lista de tipo de item checklist de rotina paginadas e filtradas pela query.
 */
export async function fetchTypeRoutineByNameAndTypeRoutine(
  options?: FetchTypeRoutineOptions
) {
  try {
    const { data } = await api.get<FetchTypeRoutineResponse>(
      '/Rotina/BuscaRotinaPorNomeETipoRotina',
      {
        params: {
          condominioId: options?.condominioId,
          nome: options?.nome,
          tipoRotina: options?.tipoRotina
        }
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
/**
 * Salva um tipo de item checklist, se houver id altera o existente.
 */
export async function saveTypeRoutine(typeRoutine: SaveTypeRoutinePayload) {
  try {
    const { data } = await api.post<TypeRoutine>(
      'Rotina/CriaRotina',
      typeRoutine
    )
    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export async function editTypeRoutine(editTypeRoutine: EditTypeRoutinePayload) {
  try {
    const { data } = await api.put<EditTypeRoutine>(
      'Rotina/EditaRotina',
      editTypeRoutine
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
export async function deleteItemTypeRoutine(
  deleteItemTypeRoutine: DeleteItemTypeRoutine
) {
  try {
    const { data } = await api.delete<DeleteItemTypeRoutine>(
      'TipoItemChecklist/DeletaTipoChecklistDaRotina',
      { data: deleteItemTypeRoutine }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
export async function deleteRoutineCondominium(
  options: DeleteRoutineCondominiumOptions
) {
  try {
    const { data } = await api.delete<TypeRoutine>(
      `/Rotina/DeletaRotina/${options.condominioId}/${options.rotinaId}`,
      {
        data: {
          condominioId: options.condominioId,
          rotinaId: options.rotinaId
        }
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
