import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Box } from '@chakra-ui/core'
import Icon from 'carbono/Icon'
import WorkEdit from 'work/views/WorkEdit'

const Login = lazy(() => import('auth/views/Login'))
const CompleteUserInfo = lazy(() => import('auth/views/CompleteUserInfo'))

const ResetPassword = lazy(() => import('auth/views/ResetPassword'))

const ChooseCondominium = lazy(() =>
  import('auth/components/ChooseCondominium')
)
const CondominiumCreate = lazy(() =>
  import('condominium/views/CondominiumCreate')
)
const CondominiumShow = lazy(() => import('condominium/views/CondominiumShow'))
const CondominiumsIndex = lazy(() =>
  import('condominium/views/CondominiumsIndex')
)
const Report = lazy(() => import('report/views/Report'))
const ItemTypeChecklistShow = lazy(() =>
  import('itemTypeChecklist/views/ItemTypeChecklistShow')
)
const ItemTypeChecklistCreate = lazy(() =>
  import('itemTypeChecklist/views/ItemTypeChecklistCreate')
)
const ItemTypeChecklistEdit = lazy(() =>
  import('itemTypeChecklist/views/ItemTypeChecklistEdit')
)
const OpenVisualiazarNC = lazy(() =>
  import('nonCompliance/components/OpenVisualiazarNC')
)
const RoutineByWorkDetails = lazy(() =>
  import('nonCompliance/components/RoutineByWorkDetails')
)
const ProtectedRoute = lazy(() => import('common/components/ProtectedRoute'))
const UserCreate = lazy(() => import('user/views/UserCreate'))
const UserShow = lazy(() => import('user/views/UserShow'))
const UserList = lazy(() => import('user/views/UserList'))
const UserDetails = lazy(() => import('user/views/UserDetails'))
const WorkCreate = lazy(() => import('work/views/WorkCreate'))
const WorkDetails = lazy(() => import('work/views/WorkDetails'))
const WorkUserBind = lazy(() => import('work/views/WorkBindUser'))
const WorksShow = lazy(() => import('work/views/WorksShow'))
const WorkShow = lazy(() => import('work/views/WorkShow'))
const ProjectDetails = lazy(() => import('project/views/ProjectDetails'))
const ItemCkecklistDetails = lazy(() =>
  import('ItemsChecklist/views/ItemCkecklistDetails')
)
const PaymentDetails = lazy(() =>
  import('paymentAttachment/views/PaymentDetails')
)
const ReleaseLetterDetails = lazy(() =>
  import('releaseLetter/view/ReleaseLetterDetails')
)
const SupportMaterial = lazy(() =>
  import('supportMaterial/views/SuppotMaterialShow')
)
const TechnicalDocumentShow = lazy(() =>
  import('technicalDocument/views/TechnicalDocumentShow')
)
const TechnicalDocumentDetails = lazy(() =>
  import('technicalDocument/views/TechnicalDocumentDetails')
)
const SubjectShow = lazy(() => import('technicalDocument/views/SubjectShow'))

const Dashboard = lazy(() => import('dashboard/views/DashboardImplantation'))
const DashboardNonCompliance = lazy(() =>
  import('dashboard/views/DashboardNonCompliance')
)

const DashboardMaintenance = lazy(() =>
  import('dashboard/views/DashboardMaintenance')
)

const NonComplianceTypeShow = lazy(() =>
  import('nonComplianceType/views/NonComplianceTypeShow')
)
const NonComplianceTypeCreate = lazy(() =>
  import('nonComplianceType/views/NonComplianceTypeCreate')
)
const NonComplianceTypeEdit = lazy(() =>
  import('nonComplianceType/views/NonComplianceTypeEdit')
)
const NonComplianceTypeDetails = lazy(() =>
  import('nonComplianceType/views/NonComplianceTypeDetails')
)
const NonComplianceCategoryShow = lazy(() =>
  import('nonComplianceCategory/views/nonComplianceCategoryShow')
)
const NonComplianceCategoryCreate = lazy(() =>
  import('nonComplianceCategory/views/nonComplianceCategoryCreate')
)
const NonComplianceCategoryEdit = lazy(() =>
  import('nonComplianceCategory/views/nonComplianceCategoryEdit')
)
const NotificationShow = lazy(() =>
  import('notifications/views/NotificationShow')
)
const NotificationDetails = lazy(() =>
  import('notifications/views/NotificationDetails')
)
const NotificationCreate = lazy(() =>
  import('notifications/views/NotificationCreate')
)

const ServiceProviderCreate = lazy(() => import('serviceProvider/views/create'))

const ServiceProviderCompanies = lazy(() =>
  import('serviceProviderCompany/views/Index')
)

const ServiceProviderCompanyCreate = lazy(() =>
  import('serviceProviderCompany/views/Create')
)

const ServiceCompanyDetails = lazy(() =>
  import('serviceProviderCompany/views/Details')
)

const ServiceCompanyEdit = lazy(() =>
  import('serviceProviderCompany/views/Edit')
)

const ServiceProviderView = lazy(() => import('serviceProvider/views/details'))

const LiberadosPortaria = lazy(() => import('liberadosPortaria/views/Index'))

const Maintenances = lazy(() => import('maintenance/screens/index'))

const CreateMaintenances = lazy(() => import('maintenance/screens/Create'))

const EditMaintenances = lazy(() => import('maintenance/screens/Edit'))

const MaintenancesDetails = lazy(() => import('maintenance/screens/Details'))

const Locations = lazy(() => import('locations/screens/Index'))

const LocationDetails = lazy(() => import('locations/screens/Details'))
const SubLocationDetails = lazy(() => import('locations/screens/SubDetails'))

const LocationEdit = lazy(() => import('locations/screens/Edit'))
const EmailsShow = lazy(() => import('email/views/Index'))

const BudgetRequest = lazy(() => import('budgets/screens/List'))

const BudgetRequestCreate = lazy(() => import('budgets/screens/Create'))

const BudgetRequestEdit = lazy(() => import('budgets/screens/Edit'))

const BudgetRequestDetails = lazy(() => import('budgets/screens/Details'))

const MaintenanceItemIndex = lazy(() => import('maintenanceItems/screens/Show'))

const MaintenanceItemDetails = lazy(() =>
  import('maintenanceItems/screens/Details')
)

const SystemItemIndex = lazy(() => import('Systems/screens/Show'))

const LicenseIndex = lazy(() => import('licenses/screens/Show'))

const LicenseCreate = lazy(() => import('licenses/screens/Create'))

const LicenseDetails = lazy(() => import('licenses/screens/Details'))

const MaintenanceCompaniesIndex = lazy(() =>
  import('maintenanceCompanies/screens/index')
)
const MaintenanceCompaniesCreate = lazy(() =>
  import('maintenanceCompanies/screens/Create')
)
const MaintenanceCompaniesEdit = lazy(() =>
  import('maintenanceCompanies/screens/Edit')
)
const MaintenanceCompaniesDetails = lazy(() =>
  import('maintenanceCompanies/screens/Details')
)
const TypeChecklistRoutineShow = lazy(() =>
  import('routineChecklistTypes/views/TypeCheckListRoutineShow')
)
const TypeChecklistRoutineCreate = lazy(() =>
  import('routineChecklistTypes/views/TypeCheckListRoutineCreate')
)
const TypeChecklistRoutineEdit = lazy(() =>
  import('routineChecklistTypes/views/TypeCheckListRoutineEdit')
)
const TypeRoutineShow = lazy(() => import('typeRoutine/views/TypeRoutineShow'))
const TypeRoutineCreate = lazy(() =>
  import('typeRoutine/views/TypeRoutineCreate')
)
const TypeRoutineDetails = lazy(() =>
  import('typeRoutine/views/TypeRoutineDetails')
)
const TypeRoutineEdit = lazy(() => import('typeRoutine/views/TypeRoutineEdit'))

const EsqueciMinhaSenha = lazy(() => import('auth/views/EsqueciMinhaSenha'))

const Calendario = lazy(() => import('maintenance/screens/Calendario'))

const Execucoes = lazy(() => import('maintenance/screens/Execucoes'))

const EventosSincronizados = lazy(() =>
  import('nonCompliance/screens/EventosSincronizados')
)

const ShowRotinaChecklist = lazy(() =>
  import('maintenance/screens/ShowRotinaChecklist')
)

const CreateRotinaChecklist = lazy(() =>
  import('maintenance/screens/CreateRotinaChecklist')
)

const ShowTipoRotinaChecklist = lazy(() =>
  import('maintenance/screens/ShowTipoRotinaChecklist')
)

const CreateTipoRotinaChecklist = lazy(() =>
  import('maintenance/screens/CreateTipoRotinaChecklist')
)

const EditTipoRotinaChecklist = lazy(() =>
  import('maintenance/screens/EditTipoRotinaChecklist')
)

const Routes = () => {
  return (
    <Suspense
      fallback={
        <Box textAlign='center' my='auto' height='100%'>
          <Icon icon='spinner' size='xs' />
        </Box>
      }
    >
      <Switch>
        <Route
          path='/'
          exact
          render={() => <Redirect to='/auth/condominium' />}
        />

        <Route path='/auth/login' component={Login} />
        <Route path='/Usuario/ResetarSenha/:token' component={ResetPassword} />
        <Route path='/auth/esqueci-minha-senha' component={EsqueciMinhaSenha} />

        <Route path='/nonCompliance/:id' component={OpenVisualiazarNC} />

        <ProtectedRoute
          path='/auth/condominium'
          component={ChooseCondominium}
        />

        <ProtectedRoute path='/usuario' exact component={CompleteUserInfo} />

        <ProtectedRoute path='/report' exact component={Report} />

        <ProtectedRoute
          path='/dashboard/implantation'
          exact
          component={() => <Dashboard />}
        />

        <ProtectedRoute
          path='/dashboard/nonCompliance'
          exact
          component={() => <DashboardNonCompliance />}
        />

        <ProtectedRoute
          path='/dashboard/maintenance'
          exact
          component={() => <DashboardMaintenance />}
        />

        <ProtectedRoute
          path='/condominiums'
          exact
          component={CondominiumsIndex}
        />

        <ProtectedRoute
          path='/condominiums/create'
          component={() => <CondominiumCreate />}
        />

        <ProtectedRoute
          path='/notifications/create'
          component={() => <NotificationCreate />}
        />

        <ProtectedRoute
          path='/notifications/:id'
          exact
          component={() => <NotificationDetails />}
        />

        <ProtectedRoute
          path='/notifications/:id/edit'
          component={() => <NotificationDetails />}
        />

        <ProtectedRoute
          path='/notifications'
          exact
          component={() => <NotificationShow />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/work/create'
          component={() => <WorkCreate />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/nonComplianceType/create'
          component={() => <NonComplianceTypeCreate />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/nonComplianceType'
          component={() => <NonComplianceTypeShow />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/nonComplianceCategory'
          exact
          component={() => <NonComplianceCategoryShow />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/nonComplianceCategory/create'
          component={() => <NonComplianceCategoryCreate />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/nonComplianceCategory/edit/:id'
          component={() => <NonComplianceCategoryEdit />}
        />

        <ProtectedRoute
          path='/nonComplianceType/:nonComplianceTypeId/details'
          exact
          component={() => <NonComplianceTypeDetails />}
        />

        <ProtectedRoute
          path='/nonComplianceType/:nonComplianceTypeId/edit'
          exact
          component={() => <NonComplianceTypeEdit />}
        />

        <ProtectedRoute
          component={ServiceProviderCompanies}
          exact
          path='/condominiums/:condominiumId/serviceProvider/companies'
        />

        <ProtectedRoute
          component={ServiceProviderCompanyCreate}
          exact
          path='/condominiums/:condominiumId/serviceProvider/companies/create'
        />

        <ProtectedRoute
          component={ServiceCompanyDetails}
          exact
          path='/condominiums/:condominiumId/serviceProvider/companies/:companyId'
        />

        <ProtectedRoute
          component={ServiceCompanyEdit}
          exact
          path='/condominiums/:condominiumId/serviceProvider/companies/:companyId/edit'
        />

        <ProtectedRoute
          path='/supportMaterial'
          component={() => <SupportMaterial />}
        />

        <ProtectedRoute
          path='/technicalDocument'
          exact
          component={() => <TechnicalDocumentShow />}
        />

        <ProtectedRoute
          path='/technicalDocument/:id/details'
          component={() => <TechnicalDocumentDetails />}
        />

        <ProtectedRoute path='/subjects' component={() => <SubjectShow />} />

        <ProtectedRoute path='/works/:id/user' component={WorkUserBind} />

        <ProtectedRoute
          path='/works/:workId/edit'
          component={() => <WorkEdit />}
        />

        <ProtectedRoute
          path='/works/:id'
          exact
          component={() => <WorkDetails />}
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId/works/:page'
          component={WorksShow}
        />

        <ProtectedRoute
          exact
          path='/work/:workId/project'
          component={ProjectDetails}
        />
        <ProtectedRoute
          exact
          path='/work/:workId/payment'
          component={PaymentDetails}
        />
        <ProtectedRoute
          exact
          path='/work/:workId/releaseLetter'
          component={ReleaseLetterDetails}
        />
        <ProtectedRoute
          exact
          path='/work/:workId/itemChecklist/:itemChecklistId'
          component={ItemCkecklistDetails}
        />

        <ProtectedRoute
          path='/work/:workId/serviceProvider/create'
          component={ServiceProviderCreate}
        />

        <ProtectedRoute path='/work/:workId' component={WorkShow} />

        <ProtectedRoute
          component={LiberadosPortaria}
          exact
          path='/condominiums/:condominiumId/concierge/allowedPersons'
        />

        <ProtectedRoute
          path='/condominiums/:condominiumId'
          component={CondominiumShow}
        />

        <ProtectedRoute exact path='/users' component={UserList} />

        <ProtectedRoute exact path='/users/create' component={UserCreate} />

        <ProtectedRoute path='/user/:id' component={UserShow} />

        <ProtectedRoute path='/users/:id' component={UserDetails} />

        <ProtectedRoute
          path='/itemTypeChecklist/:condominiumId'
          component={() => <ItemTypeChecklistShow />}
          exact
        />

        <ProtectedRoute
          path='/itemTypeChecklist/:condominiumId/create'
          component={() => <ItemTypeChecklistCreate />}
          exact
        />

        <ProtectedRoute
          path='/itemTypeChecklist/:condominiumId/edit/:id'
          component={() => <ItemTypeChecklistEdit />}
          exact
        />

        <ProtectedRoute
          component={ServiceProviderView}
          path='/works/:workId/serviceProvider/:id'
        />

        <ProtectedRoute
          component={CreateMaintenances}
          path='/maintenances/create'
          exact
        />

        <ProtectedRoute
          component={Calendario}
          path='/maintenances/calendario'
          exact
        />

        <ProtectedRoute
          component={Execucoes}
          path='/maintenances/rotinas'
          exact
        />

        <ProtectedRoute
          component={EventosSincronizados}
          path='/:condominioId/eventos-sincronizados'
          exact
        />

        <ProtectedRoute
          component={EditMaintenances}
          path='/maintenances/:id/update'
          exact
        />

        <ProtectedRoute
          component={MaintenancesDetails}
          path='/maintenances/:id'
          exact
        />

        <ProtectedRoute component={Maintenances} path='/maintenances' exact />

        <ProtectedRoute
          component={LocationEdit}
          path='/location/:id/edit'
          exact
        />

        <ProtectedRoute component={Locations} path='/locations' exact />
        <ProtectedRoute component={LocationDetails} path='/location/:id' />
        <ProtectedRoute
          component={SubLocationDetails}
          path='/sublocation/:id'
        />

        <ProtectedRoute
          component={BudgetRequestCreate}
          path='/ordem-servicos/create'
          exact
        />

        <ProtectedRoute
          component={BudgetRequestEdit}
          path='/ordem-servicos/:id/update'
          exact
        />

        <ProtectedRoute
          component={BudgetRequestDetails}
          path='/ordem-servicos/:id'
          exact
        />

        <ProtectedRoute component={BudgetRequest} path='/ordem-servicos' />

        <ProtectedRoute
          component={MaintenanceItemIndex}
          path='/maintenance-item'
          exact
        />
        <ProtectedRoute
          component={MaintenanceItemDetails}
          path='/maintenance-item/:id'
          exact
        />

        <ProtectedRoute
          component={MaintenanceCompaniesCreate}
          path='/maintenance-companies/create'
          exact
        />

        <ProtectedRoute
          component={MaintenanceCompaniesIndex}
          path='/maintenance-companies'
          exact
        />

        <ProtectedRoute
          component={MaintenanceCompaniesDetails}
          path='/maintenance-companies/:id'
          exact
        />
        <ProtectedRoute
          component={MaintenanceCompaniesEdit}
          path='/maintenance-companies/:id/update'
          exact
        />

        <ProtectedRoute component={SystemItemIndex} path='/system' />

        <ProtectedRoute component={LicenseDetails} path='/licenses/:id' exact />

        <ProtectedRoute component={LicenseIndex} path='/licenses' />

        <ProtectedRoute component={LicenseCreate} path='/license/create' />
        <ProtectedRoute path='/e-mails' component={EmailsShow} />

        {/* <ProtectedRoute
          component={WorkInspection}
          path='/workinspection'
          exact
        />
        <ProtectedRoute
          component={WorkInspectionCreate}
          path='/workinspection/create'
          exact
        /> */}
        <ProtectedRoute
          component={TypeChecklistRoutineShow}
          path='/routine/checklistTypes/:condominiumId'
          exact
        />
        <ProtectedRoute
          component={TypeChecklistRoutineCreate}
          path='/routine/checklistTypes/:condominiumId/create'
          exact
        />
        <ProtectedRoute
          component={TypeChecklistRoutineEdit}
          path='/routine/checklistTypes/:condominiumId/edit/:id'
          exact
        />
        <ProtectedRoute
          component={TypeRoutineShow}
          path='/routine/typesRoutine/:condominiumId'
          exact
        />
        <ProtectedRoute
          component={TypeRoutineCreate}
          path='/routine/typesRoutine/:condominiumId/create'
          exact
        />
        <ProtectedRoute
          component={TypeRoutineDetails}
          path='/routine/typesRoutine/:condominiumId/details/:id'
          exact
        />
        <ProtectedRoute
          component={TypeRoutineEdit}
          path='/routine/typesRoutine/:condominiumId/edit/:id'
          exact
        />
        <ProtectedRoute
          component={RoutineByWorkDetails}
          path='/routine/routineByIdWork/:obraId/:rotinaId'
          exact
        />

        <ProtectedRoute
          component={ShowRotinaChecklist}
          path='/manutencao/rotinaChecklist/:condominiumId'
          exact
        />
        <ProtectedRoute
          component={CreateRotinaChecklist}
          path='/manutencao/rotinaChecklist/:condominiumId/create'
          exact
        />

        <ProtectedRoute
          component={ShowTipoRotinaChecklist}
          path='/manutencao/tipoRotinaChecklist/:condominiumId'
          exact
        />

        <ProtectedRoute
          component={CreateTipoRotinaChecklist}
          path='/manutencao/tipoRotinaChecklist/:condominiumId/create'
          exact
        />

        <ProtectedRoute
          component={EditTipoRotinaChecklist}
          path='/manutencao/tipoRotinaChecklist/:condominiumId/:id/edit'
          exact
        />
      </Switch>
    </Suspense>
  )
}

export default Routes
